import React from 'react'
import './antiWrinkle.css';
import {Banner, Header, GalleryAWS} from '../../components';
import isrc from '../../assets/N_Mirror_Smile.jpg';
import img from '../../assets/applying_botox2.jpg';
import inject from '../../assets/inject_fce.jpeg';
import rhinjection from '../../assets/rhinjection.jpeg';
import bannerData from "../../assets/config/banner/banner-antiwrinkleinjections.json";
import {pageview} from "react-ga";
import { useEffect } from 'react';

const AntiWrinkle = () => {

  useEffect(() =>{
    pageview(window.location.pathname)
  },[]);
  
    return (
    <div>
      <Header title="Anti-Wrinkle Injection" shadow="false"/>
      <div className="page-section ">
        <Banner bannerData={bannerData} />
            <div className="container">
                <div>
                  <br />
                  <section id="letsTalkWrinkles">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>Lets talk Wrinkles...</h3>
                        <p className="standardText ">Wrinkles are a normal part of the ageing process. Winkles caused by muscle contraction such as:
                            forehead lines, frown lines and crow’s feet can be reduced and improved by botulinum toxin
                            injections (Botox).
                        </p>
                      </div>
                    </div>
                  </section>
                  <br />
                  <section id="whatIsBotox">
                    <div className="row">
                      <div className="col-md-4"><img src={isrc} className="imgborder" alt="model looking in mirror" width="100%"></img></div>
                      <div className="col-md-8 ">
                        <h3>What is Botox?</h3>
                        <p className="standardText ">
                        Botox® is a brand name for a prescription only medication called botulinum toxin A.
                        Other commercial brand names for botulinum toxin include: Bocouture, Azzalure, Xeomin
                        and Dysport.
                        </p>
                        <p className="standardText ">
                        Botox was one of the first brands of botulinum toxin to be licensed and the first brand to be
                        licensed for cosmetic use.
                        </p>
                        <p className="standardText ">
                        People commonly refer to botulinum toxin injections as Botox no matter which brand they
                        use.
                        </p>
                        <p className="standardText ">
                        Botox is a prescription only medicine and so by law you need a prescription by a doctor or a
                        trained medical prescriber. The GMC (General Medical Council) guidance states you can only
                        have a prescription of Botox after a face to face medical assessment with a medical
                        prescriber.
                        </p>
                      </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
                  <section id="howDoesBotoxWork">
                  <div className="row">
                      <div className="col-md-6">
                      <h3>How does Botox Work?</h3>
                        <p className="standardText ">Botox is a prescription only medicine used to block nerve impulses to muscles. This lessens
                        the expression strength of the muscles, softening and reducing the wrinkles.
                        </p>
                        <br />
                        </div>
                        <div className="col-md-6 ">
                        <h3>Facial anti-wrinkle injections</h3>
                        <p className="standardText ">The three areas commonly requested for Botox treatment are:</p>
                        <ul className="standardText">
                          <li>Forehead</li>
                          <li>Frown (glabella area, between the eyebrows)</li>
                          <li>Crow’s feet (eyes)</li>
                        </ul>
                      </div>

                    </div>
                  </section>
                  <br />
                  <br />
                  <section id="botoxResults">
                  <div className="row ">
                      <div className="col-md-12">
                      <h3>What results can I expect from Botox?</h3>
                      </div>
                      <div className='row'>

                        <div className="col-md-12 " >
                          <p className="standardText ">Botox usually starts to take effect 24-72 hours after injection.
                              It reaches its full effect at about 1 to 2 weeks.
                              Injections of Botox into the expression muscles gives the face a more relaxed and smoother
                              appearance.
                          </p>
                        </div>
                        <div>
                          <GalleryAWS bucketName = 'aesthetics-image-repository' prefix = 'AntiWrinkleGallery' />
                        </div>
                      </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
                  <section id="injectitionsWithDrH">
                  <div className="row">
                        <div className="col-md-8">
                        <h3>Botox Injections with Dr Hazlehurst</h3>
                        <p className="standardText ">
                        Your aesthetic aims and cosmetic results are your choice. Dr Hazlehurst will provide you
                        with an honest consultation to cover all treatment options. Dr Hazlehurst is a medically-trained doctor who has been trained to a high standard. She will put your safety and
                        cosmetic outcomes first. Rest assured your treatment will be:
                        </p>
                        <br />
                        <ul className="standardText">
                          <li>Administered by a medically trained doctor</li>
                          <li>Natural looking</li>
                          <li>Include a complimentary follow-up appointment</li>
                          <li>A discrete service</li>
                          <li>In a relaxed environment</li>
                        </ul>

                      </div>
                      <div className="col-md-4"><img src={img } className="imgborder" alt="DR hazlehurst assessing client?" width="100%"></img></div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
                  <section id="whatToExpect">
                  <div className="row">
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-11 "><img src={inject} className="imgborder" alt="Appying Botox to client frown line." width="100%"></img></div>
                          <div className="col-md-11 "><img src={rhinjection} className="imgborder" alt="Appying Botox to client frown line." width="100%"></img></div>


                        </div>
                      </div>
                      <div className="col-md-8">
                      <h3>What to expect during the appointment</h3>
                        <p className="standardText justified">Dr Hazlehurst will talk to you about your beauty and cosmetic aims and what you hope to
                        achieve with the treatment and any timescales you have and come up with a treatment
                        plan suitable to you.</p>
                        <p className="standardText justified">
                        She will go through your medical history to ensure it is safe and suitable for you to have
                        Botox treatment.
                        </p>
                        <p className="standardText justified">
                        She will explain the Botox injections procedure and any possible side effects and risks and
                        the duration to reach effect.
                        </p>
                        <p className="standardText justified">
                        She will take photos of you facial areas to be treated and ask you to do various facial
                        expressions to assess your facial muscles and wrinkles. She will then mark the areas on the
                        face to be injected with a white marker pencil.
                        </p>
                        <p className="standardText justified">
                        Your face will be cleaned with clenisept antibacterial solution to cleanse and prepare the
                        skin.
                        </p>
                        <p className="standardText justified">
                        Once you are comfortable on the couch, Dr Hazlehurst will do tiny injections of Botox with
                        an ultra-fine needle at the pre-marked facial areas. She uses the smallest needles in the
                        industry called invisible needles. Several injections are usually needed at specific sites,
                        depending on the area treated.
                        </p>
                        <p className="standardText justified">
                        There will be no downtime and you can carry on with your day afterwards.
                        </p>
                      </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
                  <section id="doesItHurt">
                  <div className="row">
                        <div className="col-md-6 padding">
                        <h3 className="centred">Does it hurt having Botox?</h3>
                        <p className="standardText justified ">
                        Dr Hazlehurst uses invisible needles, the smallest needles in the industry for maximum
                        comfort.
                        </p>
                        </div>
                        <br />
                        <div className="col-md-6 padding">
                          <h3 className="centred">How long dose Botox last?</h3>
                            <p className="standardText justified">
                            Botox is not permanent and usually lasts 3-4 months and possibly up to 6 months.
                            Most clients have their Botox treatment every 6 months to maintain the appearance.
                            </p>
                        </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
                  <section id="botoxWhenNotRecommended">
                  <div className="row">
                      <div className="col-md-6">
                        <h3 className="centred">Who is Botox not recommended for?</h3>
                        <p className="standardText ">Botox cannot be given in any of the following cases:</p>
                        <ul className="standardText">
                          <li>Pregnant or breastfeeding women or planned pregnancy</li>
                          <li>Neuromuscular disorders e.g myasthenia gravis</li>
                          <li>People taking certain medications such as muscle relaxants and aminoglycosides</li>
                          <li>Infection or inflammation at the proposed site of injection</li>
                          <li>Bleeding disorders</li>
                          <li>Allergy or sensitivity to Botox.</li>
                        </ul>
                      </div>

                        <div className="col-md-6  ">
                        <h3 className="centred">What are the possible risks and side effects?</h3>

                        <ul className="standardText">
                          <li>Redness at the treatment site for a few hours afterwards (common)</li>
                          <li>Bruising / swelling at the treatment site (less common)</li>
                          <li>Dull heavy sensation (+/- headache) (less common)</li>
                          <li>Allergic reactions (reported rarely).</li>
                          <li>Nearby muscles being affected (rarely)</li>
                          <li>Excessive weakening of the target muscles resulting in facial weakness.(very
                              rarely) This is self-limiting.</li>
                          <li>When injecting above the eyebrows, upper eyelid drooping may occur (rare).
                              This can be corrected with eyedrops but will also improve as the effects of
                              the Botox wears off.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
                  <section id="preTreatment">
                  <div className="row">
                        <div className="col-md-6">
                        <h3 className="centred">Pre-treatment advice</h3>

                      <ul className="standardText">
                        <li>Try and avoid alcohol 24 hours before treatment.</li>
                        <li>Be aware that some treatments such as aspirin, ibuprofen, clopidogrel,
                      warfarin and some supplements can make you bruise more easily. Please do
                      not stop taking treatments that you have been prescribed.</li>
                      </ul>
                      </div>
                        <div className="col-md-6">
                        <h3 className="centred">What you can and cannot do after treatment</h3>
                      <ul className="standardText">
                        <li>Do gently exercise the treatment areas by frowning, smiling and lifting your eyebrows.</li>
                        <li>Do not perform strenuous physical activity for 3 days afterwards.</li>
                        <li>Do not drink alcohol, take aspirin or ibuprofen for 24 hours as they can   increase the risk of bleeding.</li>
                        <li>Do not rub or massage the treatment areas as this may increase complications by spreading the Botox.</li>
                        <li>Avoid applying cosmetics around the injection sites to minimise the risk of infection.</li>
                      </ul>
                      </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />
              
                  <section id="considerations">
                  <div className="row centred">

                        <div className="col-md-12">
                        <h3>Other things to consider</h3>
                            <p className="standardText centered">
                            Try and schedule your treatment at least 3 weeks before any special event
                          e.g party or holiday.
                          It can take up to 14 days for the full results to be visible.
                            </p>
                      </div>
                    </div>
                  </section>
                  <br />
                  <br />
                  <br />

                  <section>
                    <div className="row">
                      <div className="col-md-12">
                           <h3>Other questions and answers:</h3>
                           <br />
                       <br />
                       </div>

                        <div className="col-md-4">
                           <h3>At what age should I get Botox?</h3>
                           <p  className="standardText ">Consider Botox from mid to late twenties to prevent wrinkles and lines forming.
                              By their late twenties most people have lines at rest.
                              Botox works well on dynamic lines, the wrinkles that form when the muscles contract.
                              Once the lines have formed, Botox is about aiming to reverse the lines rather than
                              prevention.</p>
                       </div>
                       <div className="col-md-4">
                           <h3>Will Botox treatment make me look “frozen”?</h3>
                           <p  className="standardText ">One of the biggest misconceptions people have of anti-wrinkle treatment is that they will
                            look “frozen”.
                            Skilful treatment with the correct quantities of Botox by a trained medical professional
                            provides a subtle rejuvenated look without looking “frozen” .</p>
                       </div>
                       <div className="col-md-4">
                           <h3>Can Botox be reversed</h3><br />
                           <p  className="standardText ">Botox is only temporary, with its effects only lasting a few months so any possible unwanted
                            effects will wear off. A cautious approach will be taken to reduce the chance of
                            complications.</p>
                       </div>
                    </div>
                  </section>
                </div>
            </div>
          </div>
    </div>
  )
}

export default AntiWrinkle