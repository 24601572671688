import { Review } from '../../components';
import './reviews.css';

let reviews = [
    {
        author: "Linda",
        stars: 5,
        comment: "Had a fantastic experience, definitely will go again.",
        date: "07/11/2022"
    },
    {
        author: "Rob",
        stars: 5,
        comment: "I recently had a Botox treatment with Kathryn, the initial consultation through the treatment and aftercare far exceeded my expectations. The treatment was painless and quick with outstanding results. I would recommend her to anybody looking for aesthetic treatments.",
        date: "05/11/2022"
    },
    {
        author: "",
        stars: 5,
        comment: "Excellent experience with Dr Hazlehurst, Thank you.",
        date: "31/7/2023"
    },
    {
        author: "V",
        stars: 5,
        comment: "Kathryn was lovely and really put me to ease over the treatment.",
        date: "31/7/2023"
    },
    {
        author: "B",
        stars: 5,
        comment: "Kathryn was lovely, the salon was welcoming and easy tp find from north wales x.",
        date: "9/10/2023"
    },
    {
        author: "B",
        stars: 5,
        comment: "After a thorough consultation with Kathryn and my mind was at rest, the procedure was carried out very quickly with no side effects and the area has healed well. No more skin tag!!",
        date: "9/10/2023"
    },
    {
        author: "",
        stars: 5,
        comment: "Visited Dr Hazelhurst this morning for the first time. Very professional, litened to my concerns and advised which treatments would create the desired effect. No pain, no fuss. Would recommend and will return. I can already see the results, very happy :)",
        date: "9/10/2023"
    },
    {
        author: "",
        stars: 5,
        comment: "My second Botox appointment with Dr Hazlehurst and what a great experience! She is so professional, friendly, and really puts you at ease. Dr Hazlehurst had listened to what I wanted which reflected in the results and I was really happy with it. I've had Botox elsewhere in the past, but Dr Hazlehurst has been the best. I'm looking forward to seeing the results of my second appointment.",
        date: "10/11/2023"
    },
    {
        author: "",
        stars: 5,
        comment: "Lovely, supportive and understanding approach.",
        date: "7/02/2024"
    },
    {
        author: "",
        stars: 5,
        comment: "I have been to Dr Hazlehurst several times now for Aqualyx injections. From the offset she put me at ease with her courteous, trustworthy and professional manner. The results have been great too! I certainly wouldn't go anywhere else now and would recomment her to anyone thinking of having Aesthetics procedures. I'm sure you wouldn't be dissapointed and I can guarantee you'd be in safe hands.",
        date: "7/02/2024"
    }


    
]

const Reviews = () => {
    return (    
        <div className="review-section">
            <div className="review-header">
                <h3>Reviews from my clients</h3>
            </div>
            <div className="col-md-12 ">
                <div className="row d-flex justify-content-center">
                {
                    reviews.map((review) => (
                        <Review data={review} />
                    ))
                }
                </div>
            </div>

        </div>
    )
}

export default Reviews