import { Header, GalleryAWS } from '../../components';

const Polyneucleotides = () => {
    return (
        <div>
            <Header title="Polynucleotides" shadow="false" />
            <div className="page-section">
                <div className="container">
                    <div className="max">
                        <section id="what-are-polynucleotides">
                            <div >
                                <h2>What are Polynucleotides?</h2>
                                <p className='standardText'>Polynucleotides are a revolutionary regenerative medicine treatment that work on a cellular level to repair the skin tissue and improve the skin quality on the face and body.</p>
                            </div>
                        </section>
                        <section id="Polynucleotides-gallery">
                            <div >
                                <GalleryAWS bucketName="aesthetics-image-repository" prefix="Polynucleotides-Gallery" />
                            </div>
                        </section>

                        <section id="how-do-Polynucleotides-work">
                            <div >
                                <h2>How do Polynucleotides work?</h2>
                                <p className='standardText'>Polynucleotides are natural and highly purified building blocks of DNA. They are injected into the skin and they give the skin regenerative instructions and then disappear.</p>
                                <p className='standardText'>They stimulate fibroblast cell turnover in the skin creating more collagen and elastin and  stimulate hydration and have wound healing properties.</p>
                                <p className='standardText'> They calm inflammation and rebalance melanocyte activity improving pigmentation and giving a more even skin tone.  </p>
                            </div>
                        </section>
                        <section id="what-can-polynucleotides-treat">
                            <div >
                                <h2>What can polynucleotides be used to treat?</h2>
                                <ul>
                                    <li>Fine lines</li>
                                    <li>Dark under-eye-circles</li>
                                    <li>Fine lines and wrinkles</li>
                                    <li>Skin laxity</li>
                                    <li>Acne scarring</li>
                                    <li>Poor skin tone and texture</li>
                                    <li>Hyperpigmentation</li>
                                    <li>Rosacea</li>
                                </ul>
                                <p className='standardText'>Some of the treatable areas:  Eye area, Face, neck, chest, knees, scars, stretch marks.</p>
                            </div>
                        </section>
                        <section id="Polynucleotide-product-options">
                            <div >
                                <h2>Polynucleotide product options?</h2>
                                <ul>
                                    <li><b>Plinest:</b> this range is suitable for the under-eye area as an alternative to tear trough filler, for the face, neck, chest and knees. </li>
                                    <li><b>Newest: </b> this range is for the general face area, neck, chest and knees. It is ideal for crepey or sun damaged skin as it also contains hyaluronic acid.</li>
                                    <li><b>Plenhyage: </b>this range is for the face, neck and chest area, stretch marks and scars.</li>
                                </ul>
                            </div>
                        </section>
                        <section id="how-is-it-different-to-fillers-or-skin-boosters">
                            <div >
                                <h2>How is it different from fillers or other skin boosters?</h2>
                                <p className='standardText'>Fillers pad out the skin with cross-linked hyaluronic acid.  </p>
                                <p className='standardText'>Skin boosters are generally made from non cross-linked hyaluronic acid which give your skin a hydration boost.</p>
                                <p className='standardText'>Polynucleotides give the skin its regenerative instructions and then disappear. They stimulate fibroblast and collagen production and have wound healing properties. </p>
                            </div>
                        </section>
                        <section id="what-to-expect">
                            <div >
                                <h2>What results can I expect?</h2>
                                <p className='standardText'>The results are not instant but improvement can be noted from about 3 weeks and continues to improve over a 12 week period. Patients report a general improvement in the radiance of their skin which is smoother and better hydrated. Pores and uneven colouration and texture can be improved. Fine lines and wrinkles are improved. Scarring can be improved with less depth, small and better colour. </p>
                                <p className='standardText'>Results will vary between individuals as the outcome is dependent upon the biological response of the individual and their lifestyle factors. </p>
                            </div>
                        </section>
                        <section id="who-should-avoid">
                            <div >
                                <h2>Who should avoid polynucleotide treatment?</h2>
                                <p className='standardText'>If you are pregnant or breast feeding.</p>
                                <p className='standardText'>Active skin infections. </p>
                                <p className='standardText'>People who are allergic to some forms of fish should not have polynucleotide treatment. </p>
                            </div>
                        </section>
                        <section id="side-effects">
                            <div >
                                <h2>Side Effects? - usually minimal</h2>
                                <p className='standardText'>A stinging sensation when the injections are performed. </p>
                                <p className='standardText'>Active skin infections. </p>
                                <p className='standardText'>Localised swelling, redness and or tenderness which usually settles within 72 hours but can last several days. </p>
                                <p className='standardText'>The injected product will be visible as small bumps in the skin temporarily, usually settling within 12-72 hours.</p>
                                <p className='standardText'>Bleeding at the site of injection may leave pin prick marks.</p>
                                <p className='standardText'>Bruising can occur. </p>
                                <p className='standardText'>Itching or a sensation of heat. </p>
                            </div>
                        </section>
                    
                    </div>
                </div>
            </div>
            </div>
            )
}

            export default Polyneucleotides