import React from 'react'
import './home.css';
import { Brand } from '../../components';
import { Reviews, CarouselAWS } from '../../containers';
import homeImage from '../../assets/home-self.jpeg';
import cheshireLasersLogo from '../../assets/logo/cheshireLasersLog2.png';

import { useEffect } from 'react';
import { pageview } from 'react-ga';

const Home = () => {

    useEffect(() => {
        pageview(window.location.pathname)
    }, []);


    return (
        <div>
            <div className="page-section main">
                <div className="container">
                    <div className="row-space"></div>
                    <div className="row">

                        <div className="col-sm-6">
                            <h1>Dr Hazlehurst Aesthetics</h1>

                            <p>Dr Kathryn Hazlehurst is a GP trained in Medical Aesthetics.</p>
                            <p>MBCHB MRCGP</p>
                            <p>Harley Street institute trained in anti-wrinkle (Botox) injections and dermal fillers in Medical Aesthetics.</p>
                            <p>GMC Registered</p>
                            <p>Clinic based at Cheshire Lasers, Middlewich, Cheshire.</p>
                            <p>
                                Dr Kathryn is focused on high quality, natural results to boost your natural beauty.
                                Her client satisfaction and safety are of paramount importance.
                                She offers a discrete service with a complimentary follow up appointment.
                            </p>
                            <div className="div-contacts">

                                <p className="calltoAction">Email: </p>
                                <a className="a-intext calltoAction" href="mailto:khazlehurst@doctors.org.uk?subject=aesthetics%20email">khazlehurst@doctors.org.uk</a>
                                <p className="calltoAction">Phone: </p>
                                <p>07786862241</p>
                                <p className="calltoAction">Book Now</p>
                                <button className="btn-submit booking" >

                                    <a href="https://portal.aestheticnursesoftware.com/book-online/7270">
                                        <img className="img-logo" src={cheshireLasersLogo} alt="Cheshire lasers logo" />
                                    </a>

                                </button>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img className="imgborder" src={homeImage} alt="photograph of dr hazlehurst in her clinic" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-section">
                <div className="container">
                    <div className="row-space"></div>
                    <CarouselAWS />
                </div>
            </div>
            <div className="page-section">
                <div className="container">
                    <div className="row-space"></div>
                    <Reviews />
                </div>
            </div>


        </div>
    )
}

export default Home