import { Header,Gallery } from '../../components';
import skinPeelsBeforeAfterGallery from '../../assets/config/skinpeels_ba_Gallery.json';
import skinPeelsClinicareRange from '../../assets/config/skinpeels_products.json'
import './skinpeels.css'
const Skinpeels = () => {
    return (
        <div>
        <Header title="Chemical Skin Peels" ></Header>
        <div className="page-section">
            <div className="container">
                <div className="max">
                    <section id="skinpeels">
                        <div id="what-are-chemical-skin-peels" >
                            <h2>What are Chemical Skin Peels? </h2>
                            <p className='standardText'>The chemical peel is one of the oldest cosmetic procedures in the world. Today chemical peels are among the most popular and widely recognised methods of improving the health and appearance of skin by removing dead skin cells and encouraging new ones to grow. The result is fewer fine lines, a brighter complexion, improved skin tone and a more youthful appearance.
                            </p>
                        </div>
                           <Gallery galleryItems={skinPeelsBeforeAfterGallery}/>
                        <div id="what-can-chemical-skin-peels-treat" >
                            <h2>What can Chemical Skin Peels treat? </h2>
                            <ul>
                                <li>Ageing skin, fine lines & wrinkles.</li>
                                <li>Acne: mild to moderate.</li>
                                <li>Skin tone and enlarged pores.</li>
                                <li>Hyperpigmentation.</li>
                                <li>Sun damage.</li>
                                <li>Uneven skin tone and texture</li>
                                <li>Dull or lifeless skin.</li>
                            </ul>
                        </div>
                        <div id="how-do-chemical-skin-peels-work" >
                            <h2>How do Chemical Skin Peels work? </h2>
                            <p className='standardText'>A chemical solution is applied to the surface of the skin to treat the epidermis and/or dermis. This action chemically loosens dead and damaged skin cells causing exfoliation and encouraging regeneration and rejuvenation of new skin cells. Chemical peel depth can range from very superficial to deep, although several light depth peels can often achieve similar results to one deeper peel treatment, with less risk and shorter recovery time.
                            </p>
                        </div>
                        <div id="what-to-expect" >
                            <h2>What to expect during a Chemical Skin Peel appointment with Dr Hazlehurst:</h2>
                            <ul>
                                <li>Dr Hazlehurst will talk through your skin concerns, the paper work and consent forms and select the best peel for your skin.</li>
                                <li>Before pictures will be taken for your confidential medical record.</li>
                                <li>The skin will be disinfected with an antiseptic e.g. clinisept.</li>
                                <li>An even layer of the peel will be applied with gauze.</li>
                                <li>There may be some mild tingling sensation.</li>
                                <li>The peel will be neutralised with Cliniccare Hyal + skin toner.</li>
                                <li>A calming Cliniccare EGF mask will be applied for about 20 minutes.</li>
                                <li>A course of 4-6 peels is usually required every 2 weeks.</li>
                            </ul>
                        </div>
                        <Gallery galleryItems={skinPeelsClinicareRange}/>
                       
                    </section>
                </div>
            </div>
        
        </div>
        </div>
    )

}

export default Skinpeels