import './microneedling.css'
import { Banner, Gallery, Header } from '../../components';
import data from '../../assets/config/banner/banner-microneedling.json';
import skinPeelsBeforeAfterGallery from '../../assets/config/skinpeels_ba_Gallery.json';
import { useEffect } from 'react';
import {pageview} from 'react-ga';

const Microneedling = () => {

    useEffect(() =>{
        pageview(window.location.pathname)
    },[]);

    return (
        <div>
        <Header title="Microneedling" shadow="false"></Header>
    <div className="page-section">
        <Banner  bannerData={data} />    
        <div className="container">
            <div className="max">
                <section id="microneedling">
        <div id="what-is-microneedling" >
            <h2>What is Microneedling? </h2>
            <p className='standardText'>Also known as collagen induction therapy, medical microneedling, skin needling and derma roller. <br/>
                Microneedling is a safe and effective skin rejuvenation treatment which is used to treat various skin conditions and restore the healthy youthful appearance of skin, by stimulating the natural production of <b>collagen</b> and <b>elastin</b>. With minimal downtime and no injectables, this non-invasive procedure is growing in popularity. <br/>
                Microneedling can be used to treat the face, neck, chest and the body too. <br />
            </p>
        </div>
        <Gallery galleryItems={skinPeelsBeforeAfterGallery}/>
        
        <div id="what-can-be-treaded">
            <h2>What can be treated with Microneedling?</h2>
            <ul>
                <li>Ageing skin, fine lines, wrinkles.</li>
                <li>Scars: e.g. acne scars, surgical scars.</li>
                <li>Stretch marks.</li>
                <li>Skin tone and enlarged pores.</li>
                <li>Hyperpigmentation.</li>
                <li>Sun damage.</li>
                <li>Uneven skin tone.</li>
            </ul>
        </div>
        <div id ="microneedling-and-mesotherepy">
            <h2>Microneedling & Mesotherapy application</h2>
            <p className='standardText'>Microneedling is one of the best ways to stimulate the synthesis and production of collagen. Through
                controlled micro needle insertions into the dermis layer of the skin, micro-injuries are created. This
                induced superficial trauma triggers new collagen synthesis to regenerate and thicken the skin. As a result,
                new collagen and elastin are formed reducing wrinkle depth and the appearance of scars and other skin
                imperfections, leaving the skin smoother, clearer, firmer, and younger looking.</p>
        </div>
        <div id="what-to-expect">
            <h2>What to expect during a microneedling with mesotherapy appointment with Dr Hazlehurst.</h2>
            <p className='standardText'>Dr Hazlehurst will discuss your skin concerns with you, assess your skin, explain the procedure and go
                through the paper work and consent forms.<br /><br />
                A numbing local anaesthetic cream will be applied to the skin to be treated.<br /><br />
                After 20 minutes the numbing cream will be removed with Cliniccare toner and gauze.<br /><br />
                The skin will be washed with Cliniccare concentrated cleansing foam.<br /><br />
                A Cliniccare instant painless peel will be applied and removed to prepare the skin for the procedure.<br /><br />
                The skin will be cleaned and disinfected with an antiseptic e.g. clinisept.<br /><br />
                The Cliniccare mesotherapy cocktail chosen for your skin will be applied over the treatment area.<br /><br />
                The microneedle pen, Raffine pen, will set the correct depth for each area to be treated and moved over
                the skin. The procedure does not hurt and most people find it a fairly relaxing experience.<br /><br />
                Afterwards a Cliniccare EGF mask will be applied for about 20 minutes to hydrate and soothe the skin.<br /><br />
                You can return to work etc straight away.<br /><br />
                A course of at least 3 treatments is recommended every 3-6 weeks.<br /></p>
        </div>
       
      
    </section>
    </div>
    </div>
    <script>
      </script>
    </div>
    </div>
    );

}

export default Microneedling